"use strict"

import * as jsf from '@/lib/js_functions'

export class FormField {
  #register
  #name
  #field
  constructor({ register, fields, name }) {
    this.#register = register
    this.#name = name
    this.#field = jsf.getJSONObject({ fields, name })
  }
  get field() { return this.#field }
  get value() { return this.#register[this.#name] }
  set value(value) { this.#register[this.#name] = value }
}