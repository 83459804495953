"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { lotes } from '@/lib/core/terrenos/lotes'
import { quadras } from '@/lib/core/terrenos/quadras'
import { macroAreas } from '@/lib/core/terrenos/macroAreas'
import { vtos } from '@/lib/core/public/vtos'
import { iptus } from '@/lib/core/public/iptus'
import { Form } from '@/lib/core/components/data/form/form'
import { FieldText } from '@/lib/core/components/data/form/FieldText'
import { FieldSelect } from '@/lib/core/components/data/form/FieldSelect'
export class Terrenos extends Form {
  #dataId
  #register
  #fields
  #custom
  #registerOriginal
  #lotes
  #quadras
  #macroAreas
  #vtos
  #iptus
  constructor({ Logic, component, custom }) {
    super({ component })
    this.#custom = custom
    this.#dataId = custom?.dataId || 'terrenos'
    Object.getOwnPropertyNames(Terrenos.prototype).map(key => Logic[key] = (params) => this[key](params))
  }
  build = async ({ register, fields }) => {
    super.build({ register, fields })
    this.#registerOriginal = { solicitarExcecaoDeQuadra: register.solicitarExcecaoDeQuadra } //pegar do state registerOriginal?
    const specialFields = { codigo: CodigoFieldText, quadra: QuadraSelect }
    this.createFieldsComponent({ specialFields })
    console.log(this)
    console.log(this.nome.value)
    console.log(this.codigo.value)
    console.log(this.quadra.value)
    console.log(this.quadra.link_value)
    this.#lotes = await (new lotes({ register, fields, newQuadraSelect: this.quadra, map: this.poligono.field })).build()
    this.#quadras = new quadras({ lotes: this.#lotes, register })
    this.#macroAreas = await new macroAreas({ register })
    this.#vtos = await new vtos()
    this.#iptus = await new iptus()
    await this.#fillOptionsQuadrasAndLotesFields()
    await this.#lotes.reCalculeFieldsByChangeLotes()
    this.#checkSaoPaulo({ link_cidade: register.link_cidade })
    return this
  }
  get lotes() { return this.#lotes }
  get QuadraSelect() { return this.quadra }
  get registerOriginal() { return this.#registerOriginal }
  async beforeCreate() {
    return await this.#checkDuplicatedQuadras()
        && await this.#checkLimitTerrenosPorEquipe()
  }
  async beforeCheckCreate() { return await this.codigo.setNextNumber({ dataId: this.#dataId }) }
  async beforeUpdate() {
    const update = await this.beforeCreate()
    if (super.register.link_status == "Descartado") return true
    if (this.#custom.logicExcecaoDeQuadra && this.#registerOriginal.solicitarExcecaoDeQuadra == "Sim" && super.register.excecaoDeQuadraConcedida != "Sim") {
      window.$showAlert({ type: 'error', text: 'Terrenos com solicitação de exceção não podem ser editados!', dismissible: true })
      return false
    }
    if (this.#custom.logicExcecaoDeQuadra && update) { this.#registerOriginal.solicitarExcecaoDeQuadra = super.register.solicitarExcecaoDeQuadra } //pegar do state registerOriginal?
    return update
  }
  async updateLotesLinks() { await this.#lotes.updateLotesLinks() }
  updateLotesAux() { this.#lotes.updateLotesAux() }
  clickLotes() { this.#lotes.showLotesPoligonosOnMap() }
  keyUpVTO() { this.#vtos.calculeMaxVTO({ fields: super.fields, lotes: super.register.lotes }) }
  keyUpAreaIPTU() { this.#iptus.calculeSumAreaIPTU({ register: super.register, fields: super.fields }) }
  changeMacroArea({ vmodel }) { this.#macroAreas.fillFatoresMacroAreasField({ vmodel }) }
  clickConflitos() { this.#lotes.showTerrenosConflitadosInMap() }
  changeCidade({ text }) { this.#checkSaoPaulo({ link_cidade: text }) }
  async changeDistrito() {
    super.register.quadra = ''
    this.#quadras.cleanFieldsInfosOfQuadra({ register: super.register, fields: super.fields })
    await this.#quadras.fillQuadrasFieldOptions(super.fields, super.register.distrito)
  }
  async changeQuadra() {
    await this.#quadras.reCalculeFieldsByChangeQuadras({ register: super.register, fields: super.fields })
    this.#lotes.newLotesMultilines.fillLotesFieldOptions({ newQuadraSelect: this.quadra })
  }
  async changeLote({ vmodel, value }) {
    await this.#lotes.fillLoteInfo({ _id: value, vmodel })
    await this.#lotes.reCalculeFieldsByChangeLotes()
  }
  changeStatusLote({ vmodel, value, text }) {
    this.#lotes.setColorStatusOnPoligonoLote({ status: value, link_status: text, poligono: super.register.poligono, link_lote: vmodel.link_lote })
  }
  keyUpAreaDoTerreno({ vmodel }) { this.#calculeOfertaValorTotal({ vmodel }) }
  keyUpValorporm2({ vmodel }) { this.#calculeOfertaValorTotal({ vmodel }) }
  adicionarNoMapa({ vmodel }) { this.#lotes.adicionarLoteNoMapa({ lote: vmodel.lote, link_lote: vmodel.link_lote, poligono: super.register.poligono, status: vmodel.status }) }
  changeCodlog() { this.#lotes.calculeTestadas() }
  changeTestada() { this.#lotes.calculeTestadas() }
  async removeLotesLine({ value }) {
    await this.#lotes.reCalculeFieldsByChangeLotes()
    this.#lotes.removerPoligonoLote({ poligono: super.register.poligono, link_lote: value.link_lote })
  }
  procurarEndereco() {
    if (!super.register.endereco) { return window.$showAlert({ type: 'warning', text: 'Digite uma endereço!', dismissible: true }) }
    this.#lotes.newLotesMap.setMapCenter({ address: super.register.endereco, zoom: 20, insertMarker: true })
  }
  limparEndereco() { super.register.endereco = '' }
  changeEquipe({ vmodel, text }) {
    this.#recordHistoric({ action: 'changeEquipe', label: text, equipeid: vmodel.equipe })
    vmodel.equipeBackup = text
    vmodel.detalhes = jsf.dateToString(new Date(), { addTime: true })
  }
  addResponsaveisLine({ vmodel }) { this.#enableAddedResponsavelField({ vmodel }) }
  changeStatus({ text }) {
    if (!super.register) { return { status: false, alertType: 'error', alertText: 'Não foi possível acessar o registro!' } }
    if (!super.fields) { return { status: false, alertType: 'error', alertText: 'Não foi possível acessar os campos!' } }
    const descartes = super.register.descartes.length
    if (this.#custom.logicExcecaoDeQuadra && (text == 'Descartado' || text == 'Landbank')) {
      super.register.solicitarExcecaoDeQuadra = ""
      super.register.excecaoDeQuadraConcedida = ""
      super.register.detalhes = ""
      this.#registerOriginal.solicitarExcecaoDeQuadra = ""
    }
    if (this.#custom.statusQueDescartam.some(_status => _status == text)) {
      this.#addDescarteLine()
      if (!this.#checkDescarteLineAdded({ descartes })) {
        super.register.status = ''
        super.register.link_status = ''
        window.$showAlert({ type: 'error', text: 'Não foi possível descartar! Você pode gravar o registro e entrar nele novamente para tentar de novo.', dismissible: true })
        return
      }
    }
    this.#recordHistoric({ action: 'changeStatus', label: text })
  }
  solicitarExcecaoDeQuadra() {
    super.register.excecaoDeQuadraConcedida = ''
    super.register.detalhes = ''
  }
  excecaoDeQuadraConcedida() {
    super.register.solicitarExcecaoDeQuadra = ''
    super.register.detalhes = `${window.user.name} - ${jsf.dateToString(new Date(), { addTime: true })}`
  }
  async clickRecarregarInfoLotes() {
    // Se clicar no botão sem nada no multilines e na quadra ele trava no overlay
    window.showOverlay()
    await this.#lotes.fillAllLotesInfo()
    await this.#lotes.reCalculeFieldsByChangeLotes()
    window.hideOverlay()
  }
  // ----------------------------------------------------------------------------------------------------------
  async #fillOptionsQuadrasAndLotesFields() { // eslint-disable-line
    if (!super.fields || !super.register.distrito) return
    await this.#quadras.fillQuadrasFieldOptions(super.fields, super.register.distrito)
    this.#lotes.newLotesMultilines.fillLotesFieldOptions({ newQuadraSelect: this.quadra })
  }
  async #checkLimitTerrenosPorEquipe() { // eslint-disable-line
    const { dataId, limite, statusChecados } = this.#custom
    const equipe = super.register.responsaveis?.getLast()?.link_equipe
    if (!equipe) {
      window.$showAlert({ type: 'error', text: 'É necessário vincular o terreno com uma equipe!', dismissible: true })
      return false
    }
    const terrenos = await universal.getGenericRegisters({ dataId, query: { $expr: { $eq: [{ $arrayElemAt: ["$responsaveis.link_equipe", -1] }, equipe] }, link_status: { $in: statusChecados } }, project: { _id: 1 } })
    if (!terrenos.status || !statusChecados.includes(super.register.link_status) || terrenos?.data.length <= limite || terrenos?.data.some(terreno => terreno._id == super.register._id)) return true
    window.$showAlert({ type: 'error', text: `Essa equipe já tem ${terrenos.data.length} terrenos ativos. O limite é de ${limite}!`, dismissible: true })
    return false
  }
  #checkSaoPaulo({ link_cidade }) { // eslint-disable-line
    const required = link_cidade == 'São Paulo' ? true : false
    const requireFields = ['distrito', 'quadra']
    requireFields.map(fieldName => universal.requireField({ fields: super.fields, fieldName, required }))
  }
  async #checkDuplicatedQuadras() { // eslint-disable-line
    if (super.register.solicitarExcecaoDeQuadra == "Sim" || super.register.excecaoDeQuadraConcedida == "Sim") return true
    if (super.register.link_cidade == 'São Paulo' && !super.register.link_quadra) {
      window.$showAlert({ type: 'error', text: 'Selecione uma quadra', dismissible: true })
      return false
    }
    const quadra = super.register.link_quadra?.split(' (')[0]
    const terrenosAtivosNaQuadra = await universal.getGenericRegisters({ dataId: 'terrenos_all', query: { link_quadra: quadra, link_status: { $nin: ['Descartado', 'Landbank'] } }, project: { _id: 1, responsaveis: 1 } })
    if (!quadra|| !terrenosAtivosNaQuadra.status || terrenosAtivosNaQuadra?.data?.length <= 0 || (terrenosAtivosNaQuadra?.data?.length > 0 && terrenosAtivosNaQuadra?.data?.some(terreno => terreno._id == super.register._id))) return true
    const responsavelAtual = terrenosAtivosNaQuadra.data.map(terrenoAtivoNaQuadra => terrenoAtivoNaQuadra.responsaveis.getLast())
    let terrenosPorEquipe = []
    const count = responsavel => terrenosPorEquipe.push({ equipe: responsavel.link_equipe, quantidade: jsf.countValuesInArray(responsavelAtual, 'equipe', responsavel.equipe) })
    jsf.executeIfUniq({ array: responsavelAtual, prop: 'equipe', func: count })
    const equipeList = terrenosPorEquipe.reduce((lines, terreno) => lines + `\n${terreno.equipe}: ${terreno.quantidade}`, '')
    window.$showAlert({ type: 'error', text: `Já existem terreno(s) ativo(s) nesta quadra nas equipes: ${equipeList}`, dismissible: true })
    return false
  }
  #calculeOfertaValorTotal({ vmodel }) { // eslint-disable-line
    const areaDoTerreno = jsf.numberIn(vmodel.areaDoTerreno)
    const valorporm2 = jsf.numberIn(vmodel.valorporm2)
    const valorTotal = areaDoTerreno * valorporm2
    vmodel.total = jsf.numberOut(valorTotal, 2)
  }
  #enableAddedResponsavelField({ vmodel }) { jsf.getArrayValue(vmodel, 'last').enabledLine = '' } // eslint-disable-line
  #addDescarteLine() { // eslint-disable-line
    try {
      jsf.getJSONObject({ fields: super.fields, name: 'descartes' }).enableFocus = true
      super.register.descartes.push({
        descartadoPor: window.user.name,
        data: jsf.dateToString(new Date(), { onlyTime: false }),
        horario: jsf.dateToString(new Date(), { onlyTime: true }),
        enabledLine: ''
      })
      return true
    }
    catch (error) {
      window.$showAlert({ type: 'error', text: `Ocorreu um erro: ${error}`, dismissible: true })
      return false
    }
  }
  #checkDescarteLineAdded({ descartes }) { // eslint-disable-line
    if (super.register.descartes.length != descartes + 1) { return false }
    return true
  }
  #recordHistoric({ action, label, equipeid }) { // eslint-disable-line
    const lastResponsaveis = jsf.getArrayValue(super.register.responsaveis, 'last')
    const equipeId = action == 'changeEquipe' ? equipeid : jsf.getObjectValue(lastResponsaveis, 'equipe')
    const equipeName = action == 'changeEquipe' ? label : jsf.getObjectValue(lastResponsaveis, 'equipeBackup')
    const status = action == 'changeStatus' ? label : jsf.getObjectValue(super.register, 'link_status')
    this.#addHistoricLine({ status, equipeId, equipeName })
  }
  #addHistoricLine({ status, equipeId, equipeName }) { // eslint-disable-line
    super.register.historico.push({
      data: jsf.dateToString(new Date(), { onlyTime: false }),
      horario: jsf.dateToString(new Date(), { onlyTime: true }),
      status: status,
      equipe: equipeId,
      equipeBackup: equipeName,
      alteradoPor: window.user.name
    })
  }
}

class CodigoFieldText extends FieldText {
  #showError
  constructor({ register, fields, name = 'codigo', showError }) {
    super({ register, fields, name })
    this.#showError = showError
  }
  async setNextNumber({ module, dataId }) {
    try {
      this.value = await universal.getNextNumber({ module, dataId, field: super.field.name })
      return true
    }
    catch(e) {
      if (typeof this.#showError === 'function') { this.#showError({ text: `Não foi possível obter o ${super.field.label} do terreno para efetuar o cadastro`, dismissible: true }) }
      return false
    }
  }
}
class QuadraSelect extends FieldSelect {
  constructor({ register, fields, name = 'quadra' }) {
    super({ register, fields, name })
    // this.extractInfo = this.extractInfo.bind(this)
  }
  extractInfo() {
    const quadraText = super.field.options.find(option => option.option.value == super.value).option.text
    const tipo = quadraText.split(' | ')[0]
    const [setor, quadra] = quadraText.split(' | ')[1].split(' (')[0].split('.')
    return { setor, quadra, tipo }
  }
}