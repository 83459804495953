"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class cartorios {
  #LDVM
  #currentDataId
  constructor({ register }) {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'cartorios' })
    this.#currentDataId = this.#LDVM?.getCurrentDataId({ register })
  }
  #getCartorios = async ({ action, query, project }) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: this.#currentDataId || 'cartorios', action, query, project }), returnData: true })).data
  fillCartoriosField = async ({ register, quadra }) => {
    // REMOVER || 'cartorios'
    if (!quadra) return
    const cartoriosIds = jsf.getConnectionIdByDataId(quadra.connections, this.#currentDataId || 'cartorios', 'all')
    const cartorios = cartoriosIds && await this.#getCartorios({ action: 'read/many', query: { _id: { $in: cartoriosIds } }, project: { subdistrito: 1, numero: 1 } })
    cartorios.map(cartorio => { register.cartorios.push({ cartorio: cartorio._id, link_cartorio: cartorio.subdistrito, numero: cartorio.numero }) })
  }
}