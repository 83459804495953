"use strict"

import * as jsf from '@/lib/js_functions'
import { FieldText } from '@/lib/core/components/data/form/FieldText'
import { FieldSelect } from '@/lib/core/components/data/form/FieldSelect'
import { FieldLinkSimple } from '@/lib/core/components/data/form/FieldLinkSimple'

export class Form {
  #register
  #fields
  constructor({ component }) {
    Object.getOwnPropertyNames(Form.prototype).map(key => { if (typeof this[key] === 'function') { component[key] = (params) => this[key](params) } })
  }
  get register() { return this.#register }
  set register(value) { this.#register = value }
  get fields() { return this.#fields }
  set fields(value) { this.#fields = value }
  build({ register, fields }) {
    this.#register = register
    this.#fields = fields
  }
  createFieldsComponent({ specialFields }) {
    const type = { text: FieldText, select: FieldSelect, linkSimple: FieldLinkSimple, map: FieldText }
    const object = {
      normal: (field) => new type[field.type]({ register: this.#register, fields: this.#fields, name: field.name }),
      special: (field) => new specialFields[field.name]({ register: this.#register, fields: this.#fields, name: field.name })
    }
    this.#fields
      ?.map(line => line.fields
        ?.map(field => {
          const objectType = (specialFields && Object.hasOwn(specialFields, field.name)) ? 'special' : 'normal'
          if (objectType == 'normal' && (!field.type || !type[field.type])) { return }
          Object.assign(this, { [`${field.name}`]: object[objectType](field) })
        })
      )
  }
  getField({ name }) { return jsf.getJSONObject({ fields: this.#fields, name }) }
  addField({ name, field }) { Object.assign(this, { [name]: field }) }
  addComponent({ name, component }) { this.getField({ name }).component = component }
  setFields({ value }) { this.#fields = value }
}