"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class macroAreas {
  #LDVM
  #currentDataId
  constructor({ register }) {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'macroAreas' })
    this.#currentDataId = this.#LDVM?.getCurrentDataId({ register })
  }
  #getMacroAreas = async ({ action, query, project }) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: this.#currentDataId || 'macro_areas', action, query, project }), returnData: true })).data
  fillMacroAreasField = async ({ register, quadra }) => {
    // REMOVER || 'macro_areas'
    if (!quadra) return
    const macroAreasIds = jsf.getConnectionIdByDataId(quadra.connections, this.#currentDataId || 'macro_areas', 'all')
    const macroAreas = await this.#getMacroAreas({ query: { _id: { $in: macroAreasIds } }, project: { nome: 1, FpR: 1, FpnR: 1 } })
    macroAreas.map(macroArea => register.macroAreas.push({ macroArea: macroArea._id, link_macroArea: macroArea.nome, FpR: macroArea.FpR, FpnR: macroArea.FpnR }))
  }
  fillFatoresMacroAreasField = async ({ vmodel }) => {
    const macroAreas = await this.#getMacroAreas({ action: 'read/one', query: { _id: vmodel.macroArea }, project: { FpR: 1, FpnR: 1 } })
    vmodel.FpR = macroAreas.FpR
    vmodel.FpnR = macroAreas.FpnR
  }
}