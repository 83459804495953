"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class operacoesUrbanas {
  #LDVM
  #currentDataId
  constructor({ register }) {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'operacoesUrbanas' })
    this.#currentDataId = this.#LDVM?.getCurrentDataId({ register })
  }
  #getOperacoesUrbanas = async ({ action, query, project }) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: this.#currentDataId || 'operacoes_urbanas', action, query, project }), returnData: true })).data
  fillOperacoesUrbanasField = async ({ register, quadra }) => {
    // REMOVER || 'operacoes_urbanas'
    if (!quadra) return
    const operacaoUrbanaId = jsf.getConnectionIdByDataId(quadra.connections, this.#currentDataId || 'operacoes_urbanas', 'first')
    const operacaoUrbana = operacaoUrbanaId && await this.#getOperacoesUrbanas({ action: 'read/one', query: { _id: operacaoUrbanaId }, project: { nome: 1 } })
    register.operacaoUrbana = operacaoUrbana._id
    register.link_operacaoUrbana = operacaoUrbana.nome
  }
}