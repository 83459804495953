"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
export class zoneamentos {
  #LDVM
  #currentDataId
  constructor({ register }) {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'zoneamentos' })
    this.#currentDataId = this.#LDVM?.getCurrentDataId({ register })
  }
  #getZoneamentos = async ({ action, query, project }) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: this.#currentDataId || 'lpuos2016', action, query, project }), returnData: true })).data
  fillZoneamentosField = async ({ register, quadra }) => {
    // REMOVER || 'lpuos2016'
    if (!quadra) return
    const zoneamentosIds = jsf.getConnectionIdByDataId(quadra.connections, this.#currentDataId || 'lpuos2016', 'all')
    const zoneamentos = await this.#getZoneamentos({ query: { _id: { $in: zoneamentosIds } }, project: { nome: 1, caBasico: 1, caMaximo: 1, cotaTerreno: 1, gabarito: 1, taxaOcupacao: 1, vagasPorUnidade: 1 } })
    zoneamentos.map(zoneamento => register.zoneamentos.push({ nome: zoneamento.nome, caBasico: zoneamento.caBasico, caMaximo: zoneamento.caMaximo, gabarito: zoneamento.gabarito, taxaOcupacao: zoneamento.taxaOcupacao, cotaTerreno: zoneamento.cotaTerreno }))
  }
}