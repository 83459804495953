"use strict"

import { FormField } from '@/lib/core/components/data/form/FormField'

export class FieldSelect extends FormField {
  #register
  constructor({ register, fields, name }) {
    super({ register, fields, name })
    this.#register = register
  }
  get value() { return super.value }
  set value(value) {
    super.value = value
    this.#register[`link_${super.field.name}`] = this.#getlink(value)
  }
  get link_value() { return this.#register[`link_${super.field.name}`] }
  #getlink() { return '' }
}